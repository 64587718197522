import "./Print.scss";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { Project } from "../../util/model/project";
import { RootState } from "../../state/store";
import {
  setButtonState,
  setPrintButton,
} from "../../state/features/buttonStateSlice";
import { ButtonState } from "../shared/button-state/ButtonState";
import {
  setPrintPreviewMode,
  setShowPrint,
} from "../../state/features/printSlice";
import { useTranslation } from "react-i18next";
import CheckBox from "../shared/custom/CheckBox";
import Moment from "moment";
import html2canvas from 'html2canvas';
import { FileUpload } from "primereact/fileupload";
import { Tag } from 'primereact/tag';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';


export const Print = ({ selectedPrint, printPreview }: any) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const toast = useRef<any>();
  const map = (window as any).olMap;
  const [hasNote, setHasNote] = useState<boolean>(false);
  const [hasScale, setHasScale] = useState<boolean>(false);
  
  const [scaled, setScaled] = useState<any>("");
  
  const [scale, setscale] = useState<any>("1 : 3,395,295");
  const [error, setError] = useState<number>(0);
  const projects: Project[] = useAppSelector(
    (state: RootState) => state.layer.projects
  );
  const services: any = useAppSelector((state) => state.layer.services);
  const printPreviewMode: boolean = useAppSelector(
    (state) => state.print.printPreviewMode
  );
  const [noteValue, setNoteValue] = useState<string>("");
  const [scaleValue, setScaleValue] = useState<string>("");

  const panoramaInfo = useAppSelector((state) => state.panorama.info);
  const [dateValue, setDateValue] = useState<boolean>(false);
  const [hasCompass, setHasCompass] = useState<boolean>(false);
  const [hasPanoramaLocation, setHasPanoramaLocation] =
    useState<boolean>(false);

  const closeWindow = async () => {
    document.getElementsByClassName("home")[0].classList.remove("both");
    document
      .getElementsByClassName("home")[0]
      .classList.remove("only-panorama");
    document.getElementsByClassName("home")[0].classList.remove("only-map");
    document.getElementsByClassName("home")[0].classList.remove("print-start");
    dispatch(setPrintPreviewMode(false));
    dispatch(setShowPrint(false));
    dispatch(setPrintButton(false));
    dispatch(setButtonState(ButtonState.NONE));
  };
  const viewChange = (value: any) => {
    console.log("CHECK change : ", value);
    document.getElementsByClassName("home")[0].classList.remove("both");
    document
      .getElementsByClassName("home")[0]
      .classList.remove("only-panorama");
    document.getElementsByClassName("home")[0].classList.remove("only-map");
    document.getElementsByClassName("home")[0].classList.add(value);
    selectedPrint(value);
  };
  const compassChange = (value: any) => {
    setHasCompass(value);
  };
  const [imgURL, setImgURL] = useState<any>(null);
  const [imageFile, setImageFile] = useState<any>(null);

  const [hideSaveButton, setHideSaveButton] = useState<boolean>(false)

  const canvasRef:any = useRef(null);

  const prefixFiller = (
    value: string,
    prefixValue: string,
    expectedStringLen: number
  ): string => {
    if (typeof value !== "string") {
      throw new Error("value format is not expected");
    }
    let stringValue;
    if (prefixValue.length === 0) {
      throw new Error("length of prefixValue should not equals to 0");
    }
    if (prefixValue.length > 1) {
      throw new Error("length of prefixValue should be 1");
    }
    stringValue = value;

    const len = stringValue.length;
    if (len > expectedStringLen) {
      return stringValue;
    } else {
      let result = "";
      let i = 0;
      const count = expectedStringLen - value.length;
      while (i < count) {
        i++;
        result += prefixValue;
      }
      return result + stringValue;
    }
  };

  const dateChange = (value: any) => {
    setDateValue(value);
    // if (dateValue !== '') {
    //   const date = new Date();
    //   const day = prefixFiller(date.getDate().toString(), '0', 2);
    //   const month = prefixFiller((date.getMonth() + 1).toString(), '0', 2);
    //   const year = prefixFiller((date.getFullYear()).toString(), '0', 4);
    //   const text = `${day}/${month}/${year}`;
    //   setDateValue(text);
    // } else {
    //   setDateValue('')
    // }
  };
  const panoramaInfoChange = (value: any) => {
    setHasPanoramaLocation(value);
    // setHasNote(!hasNote);
  };
  const noteChange = (value: any) => {
    setHasNote(/*!hasNote*/ value);
  };
  
  const scaleChange = (value: any) => {
    setHasScale(/*!hasNote*/ value);
    setScaled(map.getControls().getArray()[0].getScaleForResolution().toFixed(1))
  };

  const textValueChange = (value: any) => {
    setNoteValue(value);
  };

  const refreshCnavas=()=>{
    setScaled(map.getControls().getArray()[0].getScaleForResolution().toFixed(1))

    setTimeout(() => {
      capturePrintArea()

    }, 222);
  }
  useEffect(() => {
 /*    map.on('moveend', function() {
      setTimeout(() => {
        capturePrintArea()
      }, 222);
    
    }) */
  }, [])

  useEffect(() => {
    if (printPreviewMode) {
      map.on('moveend', () => {
        setScaled(map.getControls().getArray()[0].getScaleForResolution().toFixed(1));
      });

      /*document.body.classList.add('print-start');*/
      document.getElementsByClassName("home")[0].classList.add("print-start");

      setTimeout(() => {
        capturePrintArea()
      }, 222);
    
      // dispatch(setPrintPreviewMode(true))
    } else {
      /*document.body.classList.remove('print-start');*/
      document
        .getElementsByClassName("home")[0]
        .classList.remove("print-start");
        setImgURL(null)
      // dispatch(setPrintPreviewMode(false))
    }
  }, [printPreviewMode]);

  const previewChange = () => {
    if (!printPreviewMode) {
      // document.body.classList.add('print-start');
      printPreview(true)
      dispatch(setPrintPreviewMode(true));

    } else {
      // document.body.classList.remove('print-start');
      dispatch(setPrintPreviewMode(false));
      printPreview(false)
    }
    //this.noteValue = value;
  };

  const doneAfter = () => { 
    previewChange();
    setImgURL(null)
    
    setTimeout(() => {
      dispatch(setPrintButton(false));
      dispatch(setButtonState(ButtonState.NONE));
    }, 100);
  } 
  (document as any).body.onafterprint = doneAfter;

  const capturePrintArea = async () => {
    const printArea:any = document.getElementsByClassName('Map'/* 'Map' */)[0] //document.body;
    
    const pDialog:any = document.getElementsByClassName('print-dialog')[0] // Select the p-dialog element


    const righttop:any = document.getElementsByClassName('right-top only-printable')[0] 
    const lefttop:any = document.getElementsByClassName('kkn-info left-top only-printable')[0] 
    const leftbottom:any = document.getElementsByClassName('kkn-info left-bottom only-printable')[0] 
    const rightbottom:any = document.getElementsByClassName('kkn-info right-bottom only-printable')[0] 


    // const legendtitle:any = document.getElementsByClassName('only-printable Lejant')[0] 
    const legend:any = document.getElementsByClassName('only-printable Legend')[0] 


    // const combinedContainer :any  = document.createElement('div');
    
    // Get the elements
    /* const element2 :any = document.getElementById('legend'); */
    // const element1 :any = document.getElementById('home');
    
    // Append both elements into the new container
  /*   combinedContainer.appendChild(element1.cloneNode(true));
    combinedContainer.appendChild(element2.cloneNode(true)); */
    
    // Append the combined container to the body or somewhere else temporarily
    // document.body.appendChild(combinedContainer);


    // 2480.4040 x 3508 
    //  297 x 210

   // const divi:number = 1.823; // 2.60;
    const divi:number =  3.12;
    
    //2480
    //2480

   // 1349.23076

    html2canvas(printArea, { width: 3508/divi, height: 2480.4040/divi} ).then(async (canvas:any) => {
        if (pDialog) {
            pDialog.style.display = 'block';
        }
        document.body.appendChild(canvas); // For testing, you might append it to the DOM
        // const img:any = document.getElementById('legend');
        // console.log("canvas :",canvas);

        const ctx = canvas.getContext('2d');

        if(hasCompass){
          const canvasrighttop:any = await html2canvas(righttop/*,{scale:.5}*/)
          ctx.globalAlpha = 0.7;
          ctx.drawImage(canvasrighttop, canvas.width - 110, 10);
        }
        if(hasNote && noteValue){
          const canvaslefttop:any = await html2canvas(lefttop,{scale:.5})
          ctx.drawImage(canvaslefttop, 0, 0);
        }
        if(imageFile && imageFile.objectURL){
          const canvasleftbottom:any = await html2canvas(leftbottom, {scale:.5})
          ctx.globalAlpha = 1;
          ctx.drawImage(canvasleftbottom, 0, canvas.height - canvasleftbottom.height);
        }

        if(hasNote && noteValue){
          const canvasrightbottom:any = await html2canvas(rightbottom)
          ctx.globalAlpha = 1;
          ctx.drawImage(canvasrightbottom, canvas.width - canvasrightbottom.width, canvas.height - canvasrightbottom.height);
        }

        ctx.globalAlpha = 0.7;

          html2canvas(legend, /* {scale:.5} */ {width:(legend.offsetWidth), height: 580} /**/).then((canvaslegend:any) => {
            // ctx.globalAlpha = 1;
             ctx.drawImage(canvaslegend, canvas.width - canvaslegend.width, canvas.height - canvaslegend.height - 40 /*canvasrightbottom.height*/ - 4 );

           //  html2canvas(legendtitle, {scale:30 / legendtitle.offsetHeight}   /* {width:(legendtitle.offsetWidth * (17 / legendtitle.offsetHeight)), height: 17} */ /**/).then((canvaslegendtitle:any) => {
              
      
          //    ctx.drawImage(canvaslegendtitle, canvas.width - 100/*canvasrighttop.width*/, canvas.height - canvaslegend.height - 90  /*90 /*canvasrighttop.heigh//t + 34*/);

              const imgURL = canvas.toDataURL("image/png");
              setImgURL(imgURL)
  
     /*          console.log("legend :",legend);
              
              console.log("height :",legend.offsetHeight );
              console.log("width :", legend.offsetWidth *( 549 / legend.offsetHeight));
               */
           // })

          })

         

/* 
        html2canvas(righttop).then((canvasrighttop:any) => {
          // console.log(imgURL); // You can log the image URL or display it in an <img> tag
          ctx.globalAlpha = 0.7;
          
          ctx.drawImage(canvasrighttop, canvas.width - 110, 10); */

       /*    html2canvas(lefttop).then((canvaslefttop:any) => {
           // ctx.globalAlpha = 0.7;
            ctx.drawImage(canvaslefttop, 0, 0); */

          /*   html2canvas(leftbottom).then((canvasleftbottom:any) => {
               ctx.globalAlpha = 1;
               ctx.drawImage(canvasleftbottom, 0, canvas.height - canvasleftbottom.height); */

/*                html2canvas(rightbottom).then((canvasrightbottom:any) => {
                // ctx.globalAlpha = 1;
                 ctx.drawImage(canvasrightbottom, canvas.width - canvasrightbottom.width, canvas.height - canvasrightbottom.height);
                  */
    /*              html2canvas(legendtitle).then((canvaslegendtitle:any) => {
                  // ctx.globalAlpha = 1;
                   ctx.drawImage(canvaslegendtitle, canvas.width - canvasrighttop.width, canvasrighttop.height + 34);

                   html2canvas(legend).then((canvaslegend:any) => {
                    // ctx.globalAlpha = 1;
                     ctx.drawImage(canvaslegend, canvas.width - canvaslegend.width, canvas.height - canvaslegend.height - canvasrightbottom.height - 4 );
                     const imgURL = canvas.toDataURL("image/png");
                     setImgURL(imgURL)
         
                   })
       
                 }) */
     
     //          })
   
          //   })

      //    })

    
          // Draw the second canvas overlapping the first
          // Change opacity if needed by modifying the globalAlpha property
/*           ctx.globalAlpha = 0.5; // Optional: Adjust transparency of the overlapping canvas
          ctx.drawImage(canvas2, 0, 0); // Same position as the first c */

       // })
        // console.log("ctx :",ctx);
        

        // Draw something on the canvas
        // ctx.fillRect(0, 0, 200, 200);


/*           const imgWidth = element2.width;
          const imgHeight = element2.height;
      
          // Calculate the bottom-right position
          const x = canvas.width - imgWidth;
          const y = canvas.height - imgHeight -40;
       */
          // Draw the image at the calculated position
      //    ctx.drawImage(element2, x, y);

        // ctx.drawImage(element2, 200, 200);

        // Convert the canvas to a data URL (representing an image)
        // const imageData = canvas.toDataURL('image/png'); // You can specify the image format, e.g. 'image/jpeg'

        // Use the imageData to create an image element
        // const img:any = new Image();
        // img.src = imageData;
        // console.log("img :",img);
        // Append the image element to the DOM or use it further

        // Draw something on the canvas first (e.g., a rectangle)
       /*  ctx.fillStyle = "lightblue";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
   */
        // Once the image is loaded, draw it on the canvas
   /*      img.onload = function() {
          // Draw the image on the canvas at coordinates (100, 100)
          ctx.globalCompositeOperation = 'multiply';
          ctx.drawImage(img, 100, 100);
          
          // Optionally, you can use globalCompositeOperation to blend or manipulate layers
          // Example: ctx.globalCompositeOperation = 'multiply';
          // ctx.drawImage(img, 100, 100);
        }
       */

        // Append or display the canvas as needed
        // Alternatively, convert canvas to data URL for an image preview
        // document.body.removeChild(combinedContainer);
/*         const imgURL = canvas.toDataURL("image/png");
        setImgURL(imgURL) */
        // console.log(imgURL); // You can log the image URL or display it in an <img> tag
    }).catch(error => {
        console.error("Error capturing print area:", error);
    });
  }

  const startPrint = () => {
    if (printPreviewMode) {
      // const element = document.getElementsByClassName('print-dialog')[0] as HTMLElement;
      const element2 = document.getElementsByClassName('p-dialog-mask')[0] as HTMLElement;
      // element.style.display = "none"
      element2.style.display = "none";
      
      (window as any).print();
    } else {
      previewChange();
        setTimeout(() => {
        const element2 = document.getElementsByClassName('p-dialog-mask')[0] as HTMLElement;
        element2.style.display = "none";
        (window as any).print();
      }, 500);
    }
  };

  const headerTemplate = (options: any) => {
    options.props.chooseOptions.label = `${t('BUTTON.Select File')}`
    options.props.chooseOptions.icon = 'none'
    const { chooseButton } = options;

    return (
        <>
            {!hideSaveButton && chooseButton}
        </>
    );
  }
  const emptyTemplate = () => {
    return (
        <div className="flex align-items-center flex-column">
            <span style={{ padding: '10px', 'fontSize': '1em', color: 'var(--text-color-secondary)' }} className="my-5">{t('BUTTON.Select File')}</span>
        </div>
    )
  }
  
  const itemTemplate = (file: any, props: any) => {
    setImageFile(file)
    return (
        <div className="flex align-items-center flex-wrap">
            <div className="item-group">
                {file.objectURL ?
                    <Image alt={file.name} preview src={file.objectURL} /> :
                    <div className='image-box'>
                        <i className="pi pi-video" style={{ 'fontSize': '2em', color: '#863564' }}></i>
                    </div>
                }
                <span className="flex flex-column text-left ml-3">
                    {file.name}
                </span>

                <Button 
                  type="button" 
                  icon="pi pi-times" 
                  className=" ml-auto" 
                  onClick={() => onTemplateRemove(props.onRemove,file)} 
                />
            </div>
        </div>
    )
  }

  const onFileSelect = (e: any, field: any) => {

    setHideSaveButton(true)

/*     
    let findedAttFieldIndex = fields.findIndex((attField: any) => attField.id === field.id);
    const copyFields = [...fields]
    copyFields[findedAttFieldIndex] = {
        ...copyFields[findedAttFieldIndex],
        files: attachment,
        // fileSize: _totalSize
    }
    setFields(copyFields)  */
    
 /*        
     let _totalSize = field.fileSize ? field.fileSize : 0;
    const files = [...e.files]

    files.forEach((file: any) => {
        _totalSize += file.size;
    });
    const fileSizeInMegabytes = files[0]?.size / (1024 * 1024); 
   if (fileSizeInMegabytes >= 25) {
        setHideSaveButton(true)
    } else {
        setHideSaveButton(false)
    }
*/
   /*  setAttachment([...attachment, files[0]])
*/
  }

  const onTemplateRemove = (callback:any, file:any) => {
    setHideSaveButton(false)
    setImageFile(null)
    callback();

 /*    setTimeout(() => {
      const updatedAttachments = attachment.filter((item:any) => item !== file);
      setAttachment(updatedAttachments);
      dispatch(setFeatureAttachments(updatedAttachments));
    }, 0); */
  };    

  return (
    <div className="Print">
      <Toast ref={toast}></Toast>
      <Dialog
        modal={false}
        className="print-dialog"
        header={t(`BUTTON.Print`)}
        visible={true}
        onHide={closeWindow}
        resizable={false}
      >
        <div className="pair">
          <div className="item">
              <fieldset style={{display:!printPreviewMode ? "block": "none"}} className="fieldset2">
                <legend className="item-header">{t(`BUTTON.InfoSelect`)}</legend>

                <CheckBox
                  label={t(`BUTTON.NORTH`)}
                  changeChecked={compassChange}
                />
                {/* <CheckBox label={t(`BUTTON.DATE`)} changeChecked={dateChange} /> */}
                {/* <CheckBox
                  label={t(`BUTTON.PANORAMALOCATION`)}
                  changeChecked={panoramaInfoChange}
                /> */}
                
                <CheckBox label={t(`BUTTON.SCALE`)} changeChecked={scaleChange} />

                <CheckBox label={t(`BUTTON.NOTE`)} changeChecked={noteChange} />

                {hasNote && (
                  <textarea
                    style={{ marginLeft:"0px", marginBottom:"10px", height:"50px"}}
                    onChange={(e) => textValueChange(e.target.value)}
                  ></textarea>
                )}
                
                <br></br>
                <FileUpload 
                  name="demo" 
                  headerTemplate={headerTemplate} 
                  emptyTemplate={emptyTemplate} 
                  itemTemplate={itemTemplate} 
                  accept="/*" 
                  maxFileSize={5000000} 
                  onSelect={(e) => onFileSelect(e, "field")} 
                  className='input-style' 
                />
              </fieldset>

            {
              printPreviewMode &&
              <div style={{display:"flex", flexDirection:"row", justifyContent:"space-around"}}>
                <img
                  style={{zIndex:"99999"}}
                  width={594}  //594 {297} // 550   300
                  height={419.9} //420 // 210
                  src={imgURL}
                  className="print-canvas"
                />
                <Button 
                  onClick={refreshCnavas /*onClickPrint*/}
                  label={t(`TABLE.Refresh`)}
                ></Button>
                </div>
            }
          </div>

          <br></br>

          <div className="footer">
            <CheckBox
              changeChecked={previewChange}
              label={t(`BUTTON.Preview`)}
            />

            <Button
              onClick={startPrint /*onClickPrint*/}
              label={t(`BUTTON.Print`)}
            ></Button>
          </div>

          <br></br>
          {/* <span
          style={{ display:error===2?'block':'none', color:'red' }}
          >
           Aynı isimde katman var. Lütfen farklı isimde dosya seçiniz
          </span>
          */}
        </div>
      </Dialog>
      {printPreviewMode && hasCompass && (
        <div className="right-top only-printable">
          <img src="icons/north-arrow.png"></img>   
        </div>
      )}
      {printPreviewMode && hasNote && noteValue && (
        <div className="kkn-info left-top only-printable">
          {hasNote && <div style={{ whiteSpace: "pre" }}>{noteValue}</div>}
        </div>
      )}
{/* 
      {!(printPreviewMode && hasNote && noteValue) && (
        <div className="kkn-info left-top only-printable">
          <div style={{ whiteSpace: "pre" }}>{" "}</div>
        </div>
      )} */}

      {printPreviewMode && hasScale && (
        <div className="kkn-info right-bottom only-printable">
          <div style={{ whiteSpace: "pre" }}>{"Ölçek 1 : "+scaled}</div>
        </div>
      )}
      
      {imageFile && imageFile.objectURL &&
          <div className="kkn-info left-bottom only-printable">  
            <img
              style={{zIndex:"99999"}}
              // width={594}  // {297} // 550   300
              // height={420} // 210
              src={imageFile.objectURL}
              className="print-canvas"
            />

          </div>
      }
    
    </div>
  );
};
