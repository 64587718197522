import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react'
import { MapContext } from '../MapContext'
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { TileLayerContext } from './TileLayerContext';
import { TileWMS } from 'ol/source';
import ImageWMS from 'ol/source/ImageWMS';
import ImageLayer from 'ol/layer/Image';

import OSM from "ol/source/OSM";
import { getRenderPixel } from "ol/render";
import { useAppSelector } from '../../../../../state/hooks';
import { RootState } from '../../../../../state/store';
import { Project } from '../../../../../util/model/project';
import { Overlay } from 'ol';
import { fromLonLat, transform } from 'ol/proj';

interface Props {
    url: string;
    visible: boolean;
    layername?: string;
    opacity?: number;
}
export const Tile = (props: PropsWithChildren<Props>) => {
    const map = useContext(MapContext)
    const t = useRef<TileLayer<any>>(new TileLayer())

    const swipe :any= document.getElementById("swipe");
    const swipeVisibility: any = useAppSelector(state => state.buttonState.swipeButton);
    const services: any = useAppSelector(state => state.layer.services);

    const [swipes, setSwipes] = useState<any>([]);

    const projectsSwipe: Project[] = useAppSelector((state: RootState) => state.layer.projectsSwipe);
    const projects: Project[] = useAppSelector((state: RootState) => state.layer.projects);
    const [mapLayers, setMapLayers] = useState<any[]>([]);
    const [swipeLayers, setSwipeLayers] = useState<any[]>([]);
    const [overlay, setOverlay] = useState<any>()

    const layerFilterCql : any = useAppSelector(state => state.table.layerFilterCql);
    const [cqlFilter, setCqlFilter] = useState<any>([])
    const visibleDomains = useAppSelector(state => state.layer.visibleDomains)
    const [layerNames__, setLayerNames__] = useState<any>([])

    //domaine ve layerleri eşleştirme
    useEffect(() => {
        const filterByLayer: any = []
        // console.log("props. visibleDomains :",props.visibleDomains);
        // console.log("props.layername :",props.layername);
        visibleDomains.map((domain: any) => {
            layerNames__.map((layername: any) => {
                if (domain.layername === layername) {
                    filterByLayer.push(domain)
                }
            })
        })
        // console.log("filterByLayer : ",filterByLayer);

        setCqlFilter([...filterByLayer])
    }, [visibleDomains, layerNames__])


    const sortNullishValues = (arr = []) => {
        const assignValue = (val :any) => {
           if (val === null) {
              return Infinity;
           } else {
              return val;
           };
        };
        // sort((a:any, b:any) => (a.priority ? a.priority : 1 > b.priority ? b.priority : 1) ? 1 : -1).
        const sorter = (a:any, b:any) => {
           return assignValue(a.priority ? a.priority : 1) - assignValue(b.priority ? b.priority : 1);
        };
       return [...arr].sort(sorter);
     }

    useEffect(() => {
        // Create a custom canvas overlay
        const canvasOverlay:any  = document.createElement('canvas');
        canvasOverlay.width = (map as any).getSize()[0];
        canvasOverlay.height = (map as any).getSize()[1];
        canvasOverlay.style.position = 'absolute';
        canvasOverlay.style.pointerEvents = 'none'; // Allow map interactions through the overlay
        //  document.getElementById('map')?.appendChild(canvasOverlay);
        
        const ctx = canvasOverlay.getContext('2d');
        const canvasOverlay1 = new Overlay({
            element: canvasOverlay,
            positioning: 'center-center',
            stopEvent: false,
            id:'canvasOverlay1'
        });


        if(swipeVisibility){
              
            setOverlay(canvasOverlay1)
            // Update canvas size and redraw on map move/resize
            map.on('postrender', (e) => {
                
                const mapSize = (map as any).getSize();
                // Example: Draw splitter line
                //   ctx.clearRect(0, 0, canvasOverlay.width, canvasOverlay.height); // Clear previous drawings
                const width = mapSize[0] * (swipe.value / 100);
                const height = mapSize[1];
                const center:any = map.getView().getCenter();

                // Convert center coordinates to pixel coordinates
                const centerPixel = map.getPixelFromCoordinate(center);
/*                 console.log("centerPixel :",centerPixel);
                console.log("map.getCoordinateFromPixel([width, 0]); :",map.getCoordinateFromPixel(centerPixel)); */

                // To replicate getRenderPixel calculation for the corners
                const tlCoordinate = map.getCoordinateFromPixel([width, 0]); // Top-left corner
                const trCoordinate = map.getCoordinateFromPixel([mapSize[0], 0]); // Top-right corner
                const blCoordinate = map.getCoordinateFromPixel([width, mapSize[1]]); // Bottom-left corner
                const brCoordinate = map.getCoordinateFromPixel(mapSize); // Bottom-right corner
                // Convert the corner coordinates to pixel positions
                const tlPixel:any = tlCoordinate ? map.getPixelFromCoordinate(tlCoordinate) : null;
                const trPixel:any = trCoordinate ? map.getPixelFromCoordinate(trCoordinate) : null;
                const blPixel:any = blCoordinate ? map.getPixelFromCoordinate(blCoordinate) : null;
                const brPixel:any = brCoordinate ? map.getPixelFromCoordinate(brCoordinate) : null;

                                // Now you can use these pixel values just like you would with getRenderPixel
/*                 console.log('Top-left pixel:', tlPixel);
                console.log('Top-right pixel:', trPixel);
                console.log('Bottom-left pixel:', blPixel);
                console.log('Bottom-right pixel:', brPixel);
 */
                canvasOverlay.width = mapSize[0];
                canvasOverlay.height = mapSize[1];
                
           //     console.log("e:e ,",getRenderPixel(event, [width, 0]));
                ctx.restore();
                // Draw splitter line
                ctx.beginPath();
                ctx.strokeStyle = '#000000';
                ctx.lineWidth = 2.3;
                ctx.moveTo(width, 0);
                ctx.lineTo(width, mapSize[1]);
                ctx.stroke();

/*                 const tl = getRenderPixel(event, [width, 0]);
                const tr = getRenderPixel(event, [mapSize[0], 0]);
                const bl = getRenderPixel(event, [width, mapSize[1]]);
                const br = getRenderPixel(event, mapSize); */

                ctx.save();
                ctx.beginPath();
                ctx.moveTo(tlPixel[0], tlPixel[1]);
                ctx.lineTo(blPixel[0], blPixel[1]);
                ctx.lineTo(brPixel[0], brPixel[1]);
                ctx.lineTo(trPixel[0], trPixel[1]);
                
                ctx.closePath();
                ctx.clip();

            });

            // Add the overlay to the map
            map.addOverlay(canvasOverlay1);

            const lonLat = [36.122727, 37.38985];

            // Transform LonLat to the map's projection (EPSG:3857)

            const center:any = map.getView().getCenter();

            // Convert center coordinates to pixel coordinates
            const centerPixel = map.getPixelFromCoordinate(center);
            const transformedCoord = fromLonLat(centerPixel);

            // Set the position of the overlay using the transformed coordinates
            canvasOverlay1.setPosition(transformedCoord);

            // console.log("canvasOverlay1 :",canvasOverlay1);

            projectsSwipe.map((project: any) => {
              project.layer_groups.map((layerGroup: any) => {
                const layers_: any = [];
                const layerNames_: any = [];
                sortNullishValues(layerGroup.layers).map((layer: any) => {
                    if (layer.visible) {
                    layers_.push(layer);
                    layerNames_.push(layer.name);
                    }
                });
                if (layers_.length) {
                        const swipeLayer = new ImageLayer({
                            source: new ImageWMS({
                                url: `${services.find((service: any) => service.id === layers_[0].service_id)?.url}` + '/wms',
                                params: { 
                                    layers: layerNames_,
                                    cql_filter: layerNames_.map((layer:any)=>{
                                        // console.log("cqlFilter :",cqlFilter);
                
                                        const cqlFilterOfLayer :any = cqlFilter.filter((filter:any)=>filter.layername === layer)
                                        //  console.log("cqlFilterOfLayer :",cqlFilterOfLayer);
                                        // console.log("layer :",layer);
                                        if(layerFilterCql.get(layer)){
                                            const cql = layerFilterCql.get(layer).cql
                                            if(cqlFilterOfLayer.length){
                                                const cqlFilterT = cqlFilterOfLayer.map((domain: any) => `${domain.filterTag}=${+domain.domainId}`).join(' OR ')
                                                //cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL`
                                                console.log(`(${cql}) AND (` + cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL)`);
                                                
                                                return `(${cql}) AND (` + cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL)`
                                            }else{
                                                return cql
                                            }
                                        }else{
                                            if(cqlFilterOfLayer.length){
                                                const cqlFilterT = cqlFilterOfLayer.map((domain: any) => `${domain.filterTag}=${+domain.domainId}`).join(' OR ')
                                                return cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL`
                                            }else{
                                                return `1=1`
                                            }
                                        }
                                   /*  if(cqlFilterOfLayer.length){
                                        const cqlFilterT = cqlFilterOfLayer.map((domain: any) => `${domain.filterTag}=${+domain.domainId}`).join(' OR ')
                                        return props.cql_filter_ === '' ? cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL` : 
                                        props.layername_ === layer ? `(${props.cql_filter_}) AND (` + cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL)` : cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL`
                                    }else{
                                        return props.cql_filter_ === '' ? '1=1' :  props.layername_ === layer ? `${props.cql_filter_}` : '1=1'
                                    } */
                                    }).join(';')
                                },
                                serverType: "geoserver",
                                crossOrigin: "anonymous",
                            }),
                            zIndex:999999999999999999999999,
                            properties: {
                                alias: "swipe"
                            }
                        });
                        
                        swipeLayer.on("prerender", function (event) {
                            const ctx :any= event.context;
                            const mapSize:any = map.getSize();
                            const width = mapSize[0] * (swipe.value / 100);

                            ctx.restore();
                            // Draw splitter line
                            ctx.beginPath();
                            ctx.strokeStyle = '#000000';
                            ctx.lineWidth = 2.3;
                            ctx.moveTo(width, 0);
                            ctx.lineTo(width, mapSize[1]);
                            ctx.stroke();

                            const tl = getRenderPixel(event, [width, 0]);
                            const tr = getRenderPixel(event, [mapSize[0], 0]);
                            const bl = getRenderPixel(event, [width, mapSize[1]]);
                            const br = getRenderPixel(event, mapSize);

                            ctx.save();
                            ctx.beginPath();
                            ctx.moveTo(tl[0], tl[1]);
                            ctx.lineTo(bl[0], bl[1]);
                            ctx.lineTo(br[0], br[1]);
                            ctx.lineTo(tr[0], tr[1]);
                            ctx.closePath();
                            ctx.clip();
                        });


                        swipeLayer.on("postrender", function (event) {

                            const ctx:any = event.context;
                            ctx.restore();
                        });
                     
                        map.addLayer(swipeLayer);

                        setSwipeLayers([...swipeLayers, swipeLayer]);
                }
        
                });
            })


            projects.map((project: any) => {
                project.layer_groups.map((layerGroup: any) => {
                  const layers_: any = [];
                  const layerNames_: any = [];
                  sortNullishValues(layerGroup.layers).map((layer: any) => {
                      if (layer.visible) {
                      layers_.push(layer);
                      layerNames_.push(layer.name);
                      }
                  })
                  setLayerNames__(layerNames_);
                  if (layers_.length) {
                    // Create left side layer
                    const leftLayer = new ImageLayer({
                        source: new ImageWMS({
                            url: `${services.find((service: any) => service.id === layers_[0].service_id)?.url}` + '/wms',
                            params: { 
                                layers: layerNames_,

                                cql_filter: layerNames_.map((layer:any)=>{
                                    // console.log("cqlFilter :",cqlFilter);
            
                                    const cqlFilterOfLayer :any = cqlFilter.filter((filter:any)=>filter.layername === layer)
                                    //  console.log("cqlFilterOfLayer :",cqlFilterOfLayer);
                                    // console.log("layer :",layer);
                                    if(layerFilterCql.get(layer)){
                                        const cql = layerFilterCql.get(layer).cql
                                        if(cqlFilterOfLayer.length){
                                            const cqlFilterT = cqlFilterOfLayer.map((domain: any) => `${domain.filterTag}=${+domain.domainId}`).join(' OR ')
                                            //cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL`
                                            console.log(`(${cql}) AND (` + cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL)`);
                                            
                                            return `(${cql}) AND (` + cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL)`
                                        }else{
                                            return cql
                                        }
                                    }else{
                                        if(cqlFilterOfLayer.length){
                                            const cqlFilterT = cqlFilterOfLayer.map((domain: any) => `${domain.filterTag}=${+domain.domainId}`).join(' OR ')
                                            return cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL`
                                        }else{
                                            return `1=1`
                                        }
                                    }
                               /*  if(cqlFilterOfLayer.length){
                                    const cqlFilterT = cqlFilterOfLayer.map((domain: any) => `${domain.filterTag}=${+domain.domainId}`).join(' OR ')
                                    return props.cql_filter_ === '' ? cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL` : 
                                    props.layername_ === layer ? `(${props.cql_filter_}) AND (` + cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL)` : cqlFilterT +` OR ${cqlFilterOfLayer[0].filterTag} is NULL`
                                }else{
                                    return props.cql_filter_ === '' ? '1=1' :  props.layername_ === layer ? `${props.cql_filter_}` : '1=1'
                                } */
                                }).join(';')
                            },
                            serverType: "geoserver",
                            crossOrigin: "anonymous",
                        }),
                        zIndex: 999999999999999999999998,
                        properties: {
                            alias: "swipe-left"
                        }
                    });
                       // Modify the prerender for left layer
                    leftLayer.on("prerender", function (event) {

                        const ctx: any = event.context;

               
                        const mapSize: any = map.getSize();
                        const width = mapSize[0] * (swipe.value / 100);
                        
                        ctx.beginPath();
                        ctx.strokeStyle = '#000000';
                        ctx.lineWidth = 2.3;
                        ctx.moveTo(width, 0);
                        ctx.lineTo(width, mapSize[1]);
                        ctx.stroke();
                        
                        
                        ctx.save();
                        ctx.beginPath();
                        ctx.rect(0, 0, width, mapSize[1]);
                        ctx.clip();
                    });

                    // Add postrender for both layers
                    leftLayer.on("postrender", (event) => {
                        const ctx: any = event.context;
                        ctx.restore();
                    });

                    map.addLayer(leftLayer);
                    //setMapLayers([...mapLayers, leftLayer]);
                    }
                })
            })
                  
           
        }

        return () => {
            const layers = map.getLayers().getArray();
            for (const lyr of layers) {
                if(lyr.getProperties().alias === "swipe" || lyr.getProperties().alias === "swipe-left"){
                   lyr.setVisible(false)
                }
            }
            map.removeOverlay(canvasOverlay1);
        }
    }, [projectsSwipe, swipeVisibility, projects])
    useEffect(() => {
        if(swipeVisibility){

            const listener = function () {
                map.render();
            };
            swipe.addEventListener("input", listener);
            swipe.addEventListener("change", listener);
        } else{
            map.removeOverlay(overlay);
        }

    }, [swipeVisibility]);


    useEffect(() => {
        const tileLayer = t.current!;

        if (!props.layername) {

            tileLayer.setSource(new XYZ({
                url: props.url,
                crossOrigin: "anonymous"
            }));

            tileLayer.setVisible(props.visible);
            tileLayer.setZIndex(-1)
          //  setMapLayers([...mapLayers, tileLayer]);
        }

        else {
            tileLayer.setSource(new TileWMS({
                url: props.url,
                params: { 'LAYERS': `${props.layername}`, 'TILED': true },
                serverType: 'geoserver',
                crossOrigin: "Anonymous"
            }))
            tileLayer.setZIndex(10)
            tileLayer.setVisible(props.visible)
          //  setMapLayers([...mapLayers, tileLayer]);
        }
        map.addLayer(tileLayer);

        return () => {
            map.removeLayer(tileLayer);
         //   setMapLayers(mapLayers.filter((layer) => layer !== tileLayer));
        }
    }, []);


    useEffect(() => {
        const tileLayer = t.current!;
        if (props.opacity) {
            tileLayer.setVisible(true)
            tileLayer.setOpacity(props.opacity / 100)
        }
    }, [props.opacity])

    return <TileLayerContext.Provider value={t.current}>{props.children}</TileLayerContext.Provider>;
}