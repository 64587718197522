import './Map.scss'
import 'ol/ol.css';
import View from 'ol/View';
import proj4 from 'proj4';
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Map as OlMap } from 'ol';
import { MapContext } from './MapContext';
import { useAppSelector } from '../../../../state/hooks';
import { useDispatch } from 'react-redux';
import { fromLonLat, transform } from 'ol/proj';
import { setIsPanoMiniScreen, setPointerCoordinate } from '../../../../state/features/panoramaSlice';
import { register } from 'ol/proj/proj4';
import { ButtonState } from '../../../shared/button-state/ButtonState';
import ScaleLine from 'ol/control/ScaleLine';
interface IProps {
  centerX: number;
  centerY: number;
  zoom: number;
  extent: Array<any>
}

export const Map = (props: PropsWithChildren<IProps>) => {
  const mapRef = useRef(document.createElement('div'));
  const [olMap, setOlMap] = useState<OlMap | null>(null);
  const dispatch = useDispatch();
  const panoramaCoordinate = useAppSelector(state => state.panorama.panoramaCoordinate);
  const projection = useAppSelector(state => state.panorama.projection);
  const tableVisibility = useAppSelector(state => state.buttonState.tableButton);
  const extent = useAppSelector(state => state.selectedFeature.extent);
  const isPanoMiniScreen = useAppSelector(state => state.panorama.isPanoMiniScreen);
  const buttonState = useAppSelector(state => state.buttonState.buttonState);
  const externalLayerExtent = useAppSelector(state => state.layer.externalLayerExtent);

  const mouseUpUpdate = () => {
    setTimeout(() => {
      olMap?.updateSize();
    }, 100);
  }

  const [resizeObserver, setresizeObserver] = useState(new (window as any).ResizeObserver(mouseUpUpdate.bind(Map)))
  resizeObserver.observe(mapRef.current);

  document.addEventListener('mouseup', mouseUpUpdate)
  window.addEventListener('resize', mouseUpUpdate)

  useEffect(() => {
    proj4.defs('EPSG:5253',
      '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    proj4.defs('EPSG:5254',
      '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    proj4.defs('EPSG:5255',
      '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    proj4.defs('EPSG:5256',
      '+proj=tmerc +lat_0=0 +lon_0=36 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ');
    proj4.defs('EPSG:5257',
      '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ');
    proj4.defs('EPSG:5258',
      '+proj=tmerc +lat_0=0 +lon_0=42 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ');
    proj4.defs('EPSG:23035',
      '+proj=utm +zone=35 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs ');
    proj4.defs('EPSG:23036',
      '+proj=utm +zone=36 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs ');
    proj4.defs('EPSG:23037',
      '+proj=utm +zone=37 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs');
    proj4.defs('EPSG:23038',
      '+proj=utm +zone=38 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs ');
    proj4.defs('EPSG:32635',
      '+proj=utm +zone=35 +datum=WGS84 +units=m +no_defs ');
    proj4.defs('EPSG:32636',
      '+proj=utm +zone=36 +datum=WGS84 +units=m +no_defs ');
    proj4.defs('EPSG:32637',
      '+proj=utm +zone=37 +datum=WGS84 +units=m +no_defs ');
    proj4.defs('EPSG:32638',
      '+proj=utm +zone=38 +datum=WGS84 +units=m +no_defs ');
    proj4.defs('EPSG:32639',
      '+proj=utm +zone=39 +datum=WGS84 +units=m +no_defs ');
    register(proj4);
  }, [])

  useEffect(() => {
    if (tableVisibility) {
      mouseUpUpdate();
    }
  }, [tableVisibility])

  useEffect(() => {
    const view = new View({
      center: fromLonLat([36.032727, 36.63985]),
      /* center: [4219081.2965, 5008683.6939],
      zoom: 16 */
      zoom: 6,
     /*  minZoom: 5, */
      // center: [props.centerX, props.centerY],
      // zoom: props.zoom,
      // minZoom: props.zoom - 9.5,
      // maxZoom: 22,
      // extent: props.extent
    });
    const map = new OlMap({
      layers: [],
      target: mapRef.current,
      view: view,
      controls: [],
    });
    (window as any).olMap = map

    const scaleLineControl = new ScaleLine({
      units: "metric",
      bar: false,
      steps: 4,
      text: false,
      minWidth: 0,
    });
    // scaleLineControl.getScaleForResolution().toFixed(1)
    map.addControl(scaleLineControl);

    setOlMap(map);
    return () => map.dispose();
  }, [mapRef]);

  useEffect(() => {
    getCursorCoordinate();
  }, [olMap, projection])

  useEffect(() => {
    if (extent) {
      olMap?.getView().fit(extent, { duration: 500, maxZoom: 17, padding: [20, 20, 100, 20] });
    }
  }, [extent])

  useEffect(() => {
    if (externalLayerExtent.length > 0) {
      olMap?.getView().fit(externalLayerExtent, { duration: 500, maxZoom: 17, padding: [20, 20, 100, 20] });
    }
  }, [externalLayerExtent])

  const getCursorCoordinate = () => {
    olMap?.addEventListener('pointermove', (event: any) => {
      const coordinateTransform = transform(event.coordinate, 'EPSG:3857', projection);
      const coordinate = projection === 'EPSG:4326' ? [+coordinateTransform[0].toFixed(6), +coordinateTransform[1].toFixed(6)] : [+coordinateTransform[0].toFixed(4), +coordinateTransform[1].toFixed(4)]
      dispatch(setPointerCoordinate(coordinate));
    })
  }

  useEffect(() => {
    if (panoramaCoordinate) {
      const coordTransform = transform
        ([panoramaCoordinate.lon, panoramaCoordinate.lat], 'EPSG:4326', 'EPSG:3857')
      if (coordTransform) {
        olMap?.getView().setCenter(coordTransform)
      }
    }
  }, [panoramaCoordinate])


  return (
    <>
      {buttonState === ButtonState.FULLSCREEN && !isPanoMiniScreen && <div className="screen-switch-button" onClick={() => dispatch(setIsPanoMiniScreen(!isPanoMiniScreen))}>
        <img src="icons/screen-switch-button.svg" alt="" />
      </div>}
      <div className='Map' ref={mapRef}></div>
      {
        olMap &&
        <MapContext.Provider value={olMap}>
          {props.children}
        </MapContext.Provider>
      }
    </>
  )
}
