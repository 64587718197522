import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  selectedRow: false,
  features: [],
  goToSelection: false,
  tableExtent: [],
  cql_filter:'1=1',
  cqlSelectedLayer: null,
  layerFilterCql: new Map(),
  layerFilterCqlPrev: new Map()
};

export const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    setSelectedRow: (state: any, action) => {
      state.selectedRow = action.payload;
    },
    setFeatures: (state: any, action) => {
      state.features = action.payload;
    },
    setGoToSelection: (state: any, action) => {
      state.goToSelection = action.payload;
    },
    setTableExtent: (state: any, action) => {
      state.tableExtent = action.payload;
    },
    setCql: (state: any, action) => {
      state.cql_filter = action.payload;
    },
    setCqlSelectedLayer: (state: any, action) => {
      state.cqlSelectedLayer = action.payload;
    },
    setLayerFilterCql: (state: any, action) => {
      state.layerFilterCql = action.payload;
    },
    setLayerFilterCqlPrev: (state: any, action) => {
      state.layerFilterCqlPrev = action.payload;
    }
  },
});

export const { setSelectedRow, setFeatures, setGoToSelection, setTableExtent, setCql, setCqlSelectedLayer, setLayerFilterCql, setLayerFilterCqlPrev } =
  tableSlice.actions;

export default tableSlice.reducer;
