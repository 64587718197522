import { createSlice } from '@reduxjs/toolkit';
import LayerGroup from 'ol/layer/Group';
import { Project } from '../../util/model/project';

interface LayerState {
    projects: Project[],
    projectsSwipe:Project[],
    layerGroups: LayerGroup[],
    selectedLayer: {},
    styles: null,
    services: [],
    geoserverUrl: string,
    workspace: string,
    visibleDomains: any,
    visibleDomainsSwipe: any,
    isLayerVisible: boolean,
    externalLayerExtent: [],
    enabledGroupAlias: string,
    offlines: [],
    projectSwipe: any
}

const initialState: LayerState = {
    projects: [],
    projectsSwipe:[],
    layerGroups: [],
    selectedLayer: {},
    styles: null,
    services: [],
    geoserverUrl: '',
    workspace: '',
    visibleDomains: [],
    visibleDomainsSwipe: [],
    isLayerVisible: false,
    externalLayerExtent: [],
    enabledGroupAlias: "",
    offlines: [],
    projectSwipe: {}
}

export const layerSlice = createSlice({
    name: 'layer',
    initialState,
    reducers: {
        setProjects: (state: any, action) => {
            state.projects = action.payload;
        },
        setProjectsSwipe: (state: any, action) => {
            state.projectsSwipe = action.payload;
        },
        setSelectedProjectSwipe: (state: any, action) => {
            state.projectSwipe = action.payload
        },
        setLayerGroups: (state: any, action) => {
            state.layerGroups = action.payload;
        },
        setSelectedLayer: (state: any, action) => {
            state.selectedLayer = action.payload;
        },
        setSelectedLayerGeoserverUrl: (state: any, action) => {
            state.geoserverUrl = action.payload;
        },
        setWorkspace: (state: any, action) => {
            state.workspace = action.payload;
        },
        setStyles: (state: any, action) => {
            state.styles = action.payload;
        },
        setServices: (state: any, action) => {
            state.services = action.payload;
        },
        setVisibleDomains: (state: any, action) => {
            state.visibleDomains = action.payload;
        },
        setVisibleDomainsSwipe: (state: any, action) => {
            state.visibleDomainsSwipe = action.payload;
        },
        setIsLayerVisible: (state: any, action) => {
            state.isLayerVisible = action.payload;
        },
        setExternalLayerExtent: (state: any, action) => {
            state.externalLayerExtent = action.payload;
        },
        setEnabledGroupAlias: (state: any, action) => {
            state.enabledGroupAlias = action.payload;
        },
        setOfflines: (state: any, action) => {
            state.offlines = action.payload;
        }
    }
})

export const { setProjects, setLayerGroups, setVisibleDomainsSwipe, setSelectedProjectSwipe, setProjectsSwipe, setWorkspace, setSelectedLayerGeoserverUrl, setSelectedLayer, setStyles, setServices, setVisibleDomains, setIsLayerVisible, setExternalLayerExtent, setEnabledGroupAlias, setOfflines } = layerSlice.actions

export default layerSlice.reducer