import './Login.scss';
import { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { CustomizeRestApi } from '../../util/restapi/customize';
import { LoginRestApi } from '../../util/restapi/login';
import { useAppSelector } from '../../state/hooks';
import { useDispatch } from 'react-redux';
import { setCustomize, setToken } from '../../state/features/customizeSlice';
import { useNavigate } from "react-router-dom";
import { setLoginStatus, setToastVisibility } from '../../state/features/loginSlice';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import { Button } from "primereact/button";

export const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useRef<any>();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const customize = useAppSelector((state: any) => state.customize.value);

  useEffect(() => {
    // Add font link to document head
    const fontLink = document.createElement('link');
    fontLink.href = 'https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700&display=swap';
    fontLink.rel = 'stylesheet';
    document.head.appendChild(fontLink);

    CustomizeRestApi.customize().then((res: any) => {
      if (res) {
        dispatch(setCustomize(res.data.customize));
      }
    });
    localStorage.setItem('activeLanguage', 'tr');

    return () => {
      document.head.removeChild(fontLink);
    };
  }, [dispatch]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!username || !password) {
      toast.current?.show({
        severity: 'error',
        detail: t('LOGIN.Please Fill in Username and Password Field'),
        life: 3000
      });
      return;
    }

    setLoading(true);

  try {
    const res = await LoginRestApi.login(username, password);
    
    if (res?.data.token) {
      localStorage.setItem('token', res.data.token);
      dispatch(setToken(res.data.token));
      localStorage.setItem('username', username);
      dispatch(setLoginStatus('LOGIN'));
      dispatch(setToastVisibility(true));
      navigate('/');
    } else {
      dispatch(setLoginStatus('LOGOUT'));
    }
  } catch (err: any) {
    if (err.response?.status === 401) {
      toast.current?.show({
        severity: 'error',
        detail: t('LOGIN.Login Fail'),
        life: 3000
      });
    } else if (err.response?.status === 400) {
      toast.current?.show({
        severity: 'error',
        detail: t('LOGIN.Please Fill in Username and Password Field'),
        life: 3000
      });
    }
    localStorage.removeItem('token');
  } finally {
    setLoading(false);
  }
   
  };

  return (
    <div className="login">
      <div className="login_container">
        <div className="left">
          <div className="top">
            <img src={customize.logo} alt="SUEN Logo" />
          </div>
          <form onSubmit={handleSubmit} className="w-full max-w-md" autoComplete='off' style={{width:"300px"}} >
            <div className="line">
              {/* <label htmlFor="username">
                {t('LOGIN.Username')}
              </label> */}
              <InputText
                style={{ height: "3.5rem"}}
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder={t('LOGIN.Username')}
              />
            </div>
            <div className="line">
              {/* <label htmlFor="password">
                {t('LOGIN.Password')}
              </label> */}
              <Password
              style={{ height: "3.5rem"}}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                toggleMask
                placeholder={t('LOGIN.Password')}
                feedback={false}
              />
            </div>
            <div className="confirm">
              <label htmlFor="remember">
              <span style={{fontSize:"larger"}}>{t('LOGIN.Remember me')}</span>
                <Checkbox
                style={{innerHeight:"1px", outerHeight:"1px"}}
                  id="remember"
                  checked={checked}
                  onChange={e => setChecked(e.checked)}
                />
              </label>
            </div>
            <div className="login-button">
              <Button
                className="p-button-raised p-button-primary"
                type="submit"
                disabled={loading}
                label={loading ? t('LOGIN.Loading...') : t('LOGIN.Login')}
              />
            </div>
          </form>
        </div>

        <div className="right">
          <h1 className="title">SUEN CBS Portalı</h1>
          <h2 className="subtitle">Sınıraşan Su Havzaları Bilgi Sistemi</h2>
          <div className="portal-info">
            <h3 >PORTAL HAKKINDA</h3>
            <hr color="gray" style={{opacity: "0.25"}}></hr>
            <p style={{fontSize:"13px", fontFamily:"$font-family-base;"}}>
              Enstitümüz bünyesinde 2011 yılından bu yana, sınıraşan havzalarımızın
              Ülkemiz dışında kalan kısımlarına yönelik coğrafi temelli bir çok veri
              toplanmış ve üretilmiştir.
            </p>
            <p style={{fontSize:"13px"}}>
              Ülkemizin beş sınıraşan havzasına (Fırat-Dicle, Aras-Kura, Asi, Çoruh,
              Meriç) ait nehirler, göller, baraj/göletler, yerleşim yerleri, tarım alanları ve
              arıtma tesisleri gibi verilerin bu portalda sistematik bir şekilde toplanması
              ve saklanması hedeflenmiştir. Bu verilerin, Enstitümüz personelinin etkin bir
              şekilde faydalanabileceği, standartlaştırılmış bir veritabanına sahip,
              kullanımı kolay ve geliştirmeye açık bir ağ platformu üzerinde sunulması
              amaçlanmaktadır.
            </p>
          </div>
        </div>
      </div>
      <Toast ref={toast} position="bottom-center" />
    </div>
  );
};