import './Table.scss';
import { useState, useEffect, useRef } from 'react';
import { DataTable, DataTableSortOrderType } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../state/hooks';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { setSelectedFeature } from '../../../state/features/selectedFeatureSlice';
import { setButtonState, setTableButton } from '../../../state/features/buttonStateSlice';
import { setCql, setCqlSelectedLayer, setGoToSelection, setLayerFilterCql, setSelectedRow, setTableExtent } from '../../../state/features/tableSlice';
import { Calendar } from 'primereact/calendar';
import { Button } from "primereact/button";
import { useTranslation } from 'react-i18next';
import { ButtonState } from '../button-state/ButtonState';
import { setProjects, setSelectedLayer, setSelectedLayerGeoserverUrl, setWorkspace } from '../../../state/features/layerSlice';
import { setConfirmPopupVisibility, setMultipleDigiStatus } from '../../../state/features/digitizationSlice';
import { IField } from '../../../util/model/layer';
import { MultiSelect } from 'primereact/multiselect';
import axios from 'axios';
import moment from 'moment';
import { AttachmentRestApi } from '../../../util/restapi/attachment';
import { Project } from '../../../util/model/project';
import { RootState } from '../../../state/store';

interface SortProperties {
    sortField: string,
    sortOrder: DataTableSortOrderType
}

/* 
 * Table ın oluşturulduğu ve table işlemlerinin (düzenle, geometri düzenle, sil ) yapıldığı component.
 *
*/
export const Table = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dt: any = useRef(null);
    const [columns, setColumns] = useState<JSX.Element[]>([])
    const [tableData, setTableData] = useState<any>([])
    const [values, setValues] = useState<any>([])
    const [rowSelection, setRowSelection] = useState<any>([]);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [filterClear, setFilterClear] = useState<boolean>(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(100);
    const [totalRecords, setTotalRecords] = useState(0);
    const [otherFilter, setOtherFilter] = useState<any>([]);
    const [createdByFilter, setCreatedByFilter] = useState<any>([]);
    const [touchByFilter, setTouchByFilter] = useState<any>([]);
    const [allFilters, setAllFilters] = useState<any>([]);
    const [sortProperties, setSortProperties] = useState<SortProperties>({
        sortField: 'id',
        sortOrder: 1
    });
    const [filters, setFilters] = useState({});
    const [allUsers, setAllUsers] = useState<any>([]);
    const [filters3, setFilters3] = useState<any>([]);
    const selectedLayer: any = useAppSelector(state => state.layer.selectedLayer);
    const transactStatus = useAppSelector(state => state.digitization.transactStatus);
    const panoramaTransactStatus = useAppSelector(state => state.digitization.panoramaTransactStatus);
    const geoserverUrl: any = useAppSelector(state => state.layer.geoserverUrl);
    const selectedTableRow = useAppSelector(state => state.table.selectedRow);
    const services: any = useAppSelector(state => state.layer.services);
    const cancelDigiActivate = useAppSelector((state) => state.digitization.cancelDigiActivate);
    const multipleDigiStatus: any = useAppSelector(state => state.digitization.multipleDigiStatus);
    const token = localStorage.getItem('token');
    const { REACT_APP_BASE_URL } = process.env
    const [selectedFeature]: any = useAppSelector((state) => state.selectedFeature.feature);
    const buttonState: any = useAppSelector(state => state.buttonState.buttonState)
    const displayPrint = useAppSelector((state) => state.buttonState.printButton);
    const projects: Project[] = useAppSelector((state: RootState) => state.layer.projects);

    const layerFilterCql : any = useAppSelector(state => state.table.layerFilterCql);
    const [tableOpened, setTableOpened] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setTableOpened(true)
        }, 1000);
    }, [])

    useEffect(() => {
        if (!selectedFeature) {
            setRowSelection([])
        }
    }, [selectedFeature]);
    useEffect(() => {
        if (allUsers.length) {
            dispatch(setGoToSelection(false))
            setTableLoading(true);
            dispatch(setSelectedRow(false))
            dispatch(setMultipleDigiStatus(true))

            let filteredTableFields: any = selectedLayer.fields.filter((obj: any) => obj.name !== 'geom' && obj.name !== 'atta')
            let tableFields: any = filteredTableFields.sort((a: any, b: any) => a.priority - b.priority);

            const columns = []
            const filters: any = {}
            columns.push(<Column selectionMode="multiple" className='test' headerClassName='test' bodyClassName='test' headerStyle={{ width: '5px' }} bodyStyle={{ width: '5px' }} />)

            for (const field of tableFields) {

                switch (field.type) {
                    case 'Text':
                        filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
                        columns.push(<Column filter filterField={field.name} sortable key={field.id} field={field.name} header={field.alias} />)
                        break;
                    case 'Attachment':
                        filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
                        columns.push(<Column filter filterField={field.name} sortable key={field.id} field={field.name} header={field.alias} />)
                        break;
                    case 'Area':
                        filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
                        columns.push(<Column filter dataType="numeric" filterField={field.name} sortable key={field.id} field={field.name} header={field.alias} />)
                        break;
                    case 'CreatedBy':
                        filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
                        columns.push(<Column filter filterField={field.name} sortable key={field.id} field={field.name} header={field.alias} />)
                        break;
                    case 'CreatedDate':
                        filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
                        columns.push(<Column filter dataType="date" filterField={field.name} sortable key={field.id} field={field.name} header={field.alias}
                            body={(rowData) => { return dateBodyTemplate(rowData, field.name) }} filterElement={dateFilterTemplate} />)
                        break;
                    case 'TouchBy':
                        filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
                        columns.push(<Column filter filterField={field.name} sortable key={field.id} field={field.name} header={field.alias} />)
                        break;
                    case 'TouchDate':
                        filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
                        columns.push(<Column filter dataType="date" filterField={field.name} sortable key={field.id} field={field.name} header={field.alias}
                            body={(rowData) => { return dateBodyTemplate(rowData, field.name) }} filterElement={dateFilterTemplate} />)
                        break;
                    case 'Domain':
                        columns.push(<Column filter showFilterMatchModes={false} sortable key={field.id} field={field.name} header={field.alias}
                            filterElement={(options) => { return domainFilterTemplate(options, field) }} />)
                        filters[field.name] = { value: null, matchMode: FilterMatchMode.IN }
                        break;
                    case 'Integer':
                        if (field.name === 'il_kodu' || field.name === 'ilce_kodu' || field.name === 'mahalle_kodu' || field.name === 'tkgm_il_kodu' || field.name === 'tkgm_ilce_kodu' || field.name === 'tkgm_mahalle_kodu') {
                            filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
                            columns.push(<Column filter filterField={field.name} sortable key={field.id} field={field.name} header={field.alias} />)
                        }
                        else {
                            filters[field.name] = { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
                            columns.push(<Column filter filterField={field.name} sortable key={field.id} field={field.name} header={field.alias} />)
                        }
                        break;
                    default:
                        columns.push(<Column sortable key={field.id} field={field.name} header={field.alias} />)
                        break;
                }
            }

            setColumns(columns);
            setFilters(filters);
            getFeatures();
            setTimeout(() => {
                if (filterClear) {
                    setFilterClear(false)
                }
            }, 100);
        }
    }, [filterClear, allUsers])


    useEffect(() => {

        if(!layerFilterCql.get(selectedLayer.name)){
            setFilters({})
            setSortProperties({
                sortField: 'id',
                sortOrder: 1
            })
            // setAllFilters
            setAllFilters([]);
            //setFilterClear(true)
        }
        
        setTimeout(() => {
            setTableOpened(true)
            setTimeout(() => {
                if(layerFilterCql.get(selectedLayer.name)){
                    const layerFiltersGet = layerFilterCql.get(selectedLayer.name).filters
                    
                    for (const filter of layerFiltersGet) {
                        if(filter[2] === 'in'){
                            dt.current.filter(...filter)
                            dt.current.filter(...filter)
                        }else{
                            dt.current.filter(...filter)
                        }
                    }
                }
            }, 100);
        }, 100);

        // setOtherFilter([]); setCreatedByFilter([]); setFilterClear(true)
    }, [selectedLayer]);

    useEffect(() => {
        if(tableOpened){
            clearSelectedData()
            getFeatures()
        }
    }, [allFilters, first, rows, sortProperties, transactStatus, panoramaTransactStatus, multipleDigiStatus])

    useEffect(() => {
        if (!selectedTableRow) {
            dispatch(setSelectedFeature([]))
            setRowSelection([])
            dispatch(setGoToSelection(false))
        }
    }, [selectedTableRow])

    useEffect(() => {
        if (rowSelection.length > 0) {
            dispatch(setButtonState(ButtonState.NONE))
            selectionHandler(rowSelection)
        } else {
            dispatch(setSelectedFeature([]))
            dispatch(setSelectedRow(false))
            dispatch(setGoToSelection(false))
        }
    }, [rowSelection])

    useEffect(() => {
        getUsers()
    }, [token])

    useEffect(() => {
        if ((otherFilter && otherFilter.length > 0) || (createdByFilter && createdByFilter.length > 0) || (touchByFilter && touchByFilter.length > 0)) {
            setTimeout(() => {
                setAllFilters([...otherFilter, ...createdByFilter, ...touchByFilter])
            }, 1000);
        } else {
            setAllFilters([...otherFilter, ...createdByFilter, ...touchByFilter])
        }
    }, [otherFilter, createdByFilter, touchByFilter])

    useEffect(() => {
        if (cancelDigiActivate) {
            dispatch(setSelectedFeature([]))
            setRowSelection([])
        }
    }, [cancelDigiActivate])

    const goToSelectedData = async () => {
        dispatch(setGoToSelection(true))
    }

    const getFeatures = async () => {
        setTableData([]);
        setTableLoading(true)
        let features: any;
        let score: any;

        const cql_filter_:any = allFilters.map((element: any) => `(${element.key}${element.matchMode}${element.value})`).join(' AND ')
        // dispatch(setCql(cql_filter_))

        const _layerFilterCql = new Map();

    
        if(filters3.length){

            for (const [key, value] of layerFilterCql) {
                _layerFilterCql.set(key, value)
            }

            _layerFilterCql.set(selectedLayer.name, { filters: [...filters3], cql: cql_filter_})
            dispatch(setLayerFilterCql(_layerFilterCql))
        }else{

            for (const [key, value] of layerFilterCql) {
                if(key !== selectedLayer.name){
                    _layerFilterCql.set(key, value)
                }
            }

            dispatch(setLayerFilterCql(_layerFilterCql))
        }

        // dispatch(setCqlSelectedLayer(selectedLayer))
        
        const copyProjects: any = [...projects]
   /*      setTimeout(() => {
            
        }, timeout); */
            dispatch(setProjects([...copyProjects]))

        // console.log("filter3 :",filters3)

        const response = await axios.get(geoserverUrl +
            `/ows?service=WFS&version=1.0.0&srsname=EPSG:3857&maxFeatures=${rows}&startIndex=${first}&${allFilters && allFilters.length > 0 && `cql_filter=${cql_filter_}`}&sortBy=${sortProperties.sortField + (sortProperties.sortOrder === 1 ? '+A' : '+D')}&version=1.0.0&request=getfeature` + (token ? `&token=${token}` : '') + `&outputFormat=application/json&typeName=${selectedLayer.name}`)
        features = response.data.features
        score = response.data.totalFeatures;
        dispatch(setTableExtent(response.data.bbox))
        const properties = features?.map((feature: any) => feature.properties);
        let attachmentArray: any[] = []
        if (properties) {
            try {
                const attachmentsResponse = await AttachmentRestApi.getAttachmentListByLayerId(selectedLayer.id)
                attachmentArray = (attachmentsResponse.data && attachmentsResponse.data.data) ? attachmentsResponse.data.data : []
            } catch (error) {
                console.log(error)
            }
        }


        if (features) {
            const fields = selectedLayer.fields.filter((obj: any) => obj.name !== 'geom');
            const values: any = [];
            const attachmentArr = attachmentArray;
            let attachmentsNameArr: any[] = []
            var areaFormatter: any = new Intl.NumberFormat();
            for (const feature of features) {
                const properties: any = {}

                for (const field of fields) {
                    switch (field.type) {

                        case 'Attachment':
                            for (const attach of attachmentArr) {
                                if (attach.feature_id === feature.properties.id) {
                                    attachmentsNameArr = []
                                    attachmentsNameArr.push(attach.filename)
                                    properties[field.name] = attachmentsNameArr.join(', ');
                                }
                            }
                            break;
                        case 'Domain':
                            properties[field.name] = field.domain?.find((obj: any) => obj.id === feature.properties[field.name])?.desc;
                            break;
                        case 'Domain':
                            properties[field.name] = field.domain?.find((obj: any) => obj.id === feature.properties[field.name])?.desc;
                            break;
                        case 'CreatedBy':
                            properties[field.name] = allUsers.find((obj: any) => obj.id === feature.properties[field.name])?.username;
                            break;
                        case 'CreatedDate':
                            properties[field.name] = feature.properties[field.name] !== 'Invalid Date' ? new Date(feature.properties[field.name]) : '';
                            break;
                        case 'TouchBy':
                            properties[field.name] = allUsers.find((obj: any) => obj.id === feature.properties[field.name])?.username;
                            break;
                        case 'TouchDate':
                            properties[field.name] = feature.properties[field.name] !== 'Invalid Date' ? new Date(feature.properties[field.name]) : '';
                            break;
                        case 'Area':
                            properties[field.name] = feature.properties[field.name] ? areaFormatter.format(feature.properties[field.name].toFixed(2)) : '';
                            break;
                        case 'Float':
                            properties[field.name] = feature.properties[field.name] ? areaFormatter.format(feature.properties[field.name].toFixed(2)) : '';
                            break;
                        default:
                            properties[field.name] = feature.properties[field.name];
                            break;
                    }
                }
                values.push(properties)
            }
            setValues([...values])

            setTotalRecords(score ? score : features.length);
            setTableLoading(false);
        } else {
            setTableLoading(false);
        }

    }

    const onPage = (event: any) => {
        setRows(event.rows);
        setFirst(event.first);
    }

    const onSort = (event: any) => {
        setSortProperties({ sortField: event.sortField, sortOrder: event.sortOrder })
    }

    const onFilter = async (event: any) => {
        const filters = event.filters;
        const allFilters: any = []

        const filterVariables: any = []
        for (const [key, value] of Object.entries(filters)) {
            allFilters.push({ key, value })
            if ((value as any).constraints && (value as any).constraints.length > 0) {
                if((value as any).constraints[0].value){
                    filterVariables.push([(value as any).constraints[0].value, key, (value as any).constraints[0].matchMode])
                }
            }
            if ((value as any).value && (value as any).value.length > 0) {
                const filterValue = (value as any)
                if (filterValue.matchMode === 'in') {
                    const valueArray = filterValue.value
                    filterVariables.push([valueArray, key, filterValue.matchMode])
                }
            }
        }
        setFilters3(filterVariables)
        // console.log("dt :",dt)

        const result = await createFilters(allFilters)
        // console.log("result :",result)

        const usersFilters = result.filter((filter: any) => filter.key === 'createdBy')
        const usersFilters2 = result.filter((filter: any) => filter.key === 'touchBy')
        const otherFilters = result.filter((filter: any) => filter.key !== 'createdBy' && filter.key !== 'touchBy')

        const res = await searchInUsers2(filters.createdBy.constraints[0], usersFilters)
        const res2 = await searchInUsers2(filters.touchBy.constraints[0], usersFilters2)

        setCreatedByFilter(res)
        setTouchByFilter(res2)
        setOtherFilter(otherFilters); 
        setFirst(0);
    }

    const createFilters = (allFilters: any) => {
        const usedFilters: any = []

        if (allFilters.length > 0) {
            allFilters.forEach((filter: any) => {
                if (filter.value.constraints && filter.value.constraints.length > 0) {
                    filter.value.constraints.forEach((item: any) => {
                        if (item.value) {
                            if (item.matchMode === 'startsWith') {
                                usedFilters.push({ key: filter.key, matchMode: ' like ', value: `'${item.value}%25'` });
                            }
                            if (item.matchMode === 'contains') {
                                usedFilters.push({ key: filter.key, matchMode: ' like ', value: `'%25${item.value}%25'` });
                            }
                            if (item.matchMode === 'notContains') {
                                usedFilters.push({ key: filter.key, matchMode: ' not like ', value: `'%25${item.value}%25'` });
                            }
                            if (item.matchMode === 'endsWith') {
                                usedFilters.push({ key: filter.key, matchMode: ' like ', value: `'%25${item.value}'` });
                            }
                            if (item.matchMode === 'equals') {
                                usedFilters.push({ key: filter.key, matchMode: '=', value: `'${item.value}'` });
                            }
                            //for dates
                            if (item.matchMode === 'dateIs') {
                                let localDateTime = moment(item.value).format('YYYY-MM-DDTHH:mm:ss');
                                usedFilters.push({ key: filter.key, matchMode: '=', value: `'${localDateTime + 'Z'}'` });
                            }
                            if (item.matchMode === 'dateBefore') {
                                let localDateTime = moment(item.value).format('YYYY-MM-DDTHH:mm:ss');
                                usedFilters.push({ key: filter.key, matchMode: '<', value: `'${localDateTime + 'Z'}'` });
                            }
                            if (item.matchMode === 'dateAfter') {
                                let localDateTime = moment(item.value).format('YYYY-MM-DDTHH:mm:ss');
                                usedFilters.push({ key: filter.key, matchMode: '>', value: `'${localDateTime + 'Z'}'` });
                            }
                        }
                    })
                }
                //for domain
                if (filter.value.value && filter.value.value.length > 0) {
                    const filterValue = filter.value
                    if (filterValue.matchMode === 'in') {
                        const valueArray = filterValue.value
                        usedFilters.push({ key: filter.key, matchMode: '=', value: valueArray.map((value: any) => `${value}`).join(` OR ${filter.key}=`) });
                    }
                };
            });
            return usedFilters
        }
    }

    const searchInUsers2 = async (filter: any, filters: any) => {
        if (filters && filters.length > 0) {
            const usersFilters: any[] = []
            await axios.get(process.env.REACT_APP_BASE_URL + `rest/v1/user/lazyLoad/${rows}/${first}/id/1/${filter.matchMode}/username/${filter.value}`, { params: { token: token } })
                .then((response: any) => {
                    const usersResponse = response.data.users

                    if (usersResponse && usersResponse.length > 0) {

                        for (const filter of filters) {
                            if (usersResponse.length === 1) {
                                usersFilters.push({ key: filter.key, matchMode: '=', value: usersResponse.length > 0 && usersResponse.map((arr: any) => arr.id).join(',') })
                            }
                            if (usersResponse.length > 1) {
                                usersFilters.push({ key: filter.key, matchMode: ' in ', value: `(${usersResponse.length > 0 && usersResponse.map((arr: any) => arr.id).join(',')})` })
                            }
                        }

                    }
                })
            return usersFilters
        } else { return [] }
    }

    const getUsers = async () => {
        const usersResponse = await axios.get(REACT_APP_BASE_URL + `rest/v1/user?token=${token}`)
        setAllUsers(usersResponse.data.users)
    }

    const selectionHandler = async (selectedFeature: any) => {
        let selectionsArr: any[] = []

        const service = services.find((service: any) => { return service.layers.some((layerObj: any) => layerObj.name === selectedLayer.name) })
        if (service) {
            for (const feature of selectedFeature) {
                try {
                    const response = await axios.get(service!.url + `/ows?service=WFS&version=1.0.0&srsname=EPSG:3857&request=getfeature` + (token ? `&token=${token}` : '') + `&featureId=${feature.id}&typeName=${selectedLayer.name}&outputFormat=application/json`)
                    const findedFeature = response.data.features[0]
                    selectionsArr.push(findedFeature)
                } catch (error) {
                    console.log(error);
                }
            }
        }
        dispatch(setSelectedFeature([...selectionsArr]))
        dispatch(setSelectedRow(true))
    }

    const clearSelectedData = () => {
        dispatch(setSelectedFeature([]))
        setRowSelection([])
    }
    const editSelectedData = () => {
        dispatch(setButtonState(ButtonState.EDIT));
        dispatch(setConfirmPopupVisibility(true));
    }

    function downloadCSV(data: object[], filename: string): void {
        const csv = convertArrayOfObjectsToCSV(data);
        const link = document.createElement('a');
        link.setAttribute('href', csv);
        link.setAttribute('download', `${filename}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    
    function convertArrayOfObjectsToCSV(data: object[]): string {
        data = data || []
        let filteredTableFields: any = selectedLayer.fields.filter((obj: any) => obj.name !== 'geom' && obj.name !== 'atta')
        const headers = Object.keys(data[0]).map((value, index) => filteredTableFields[index] && filteredTableFields[index].alias ? `"${filteredTableFields[index].alias}"` : `""`).join(',');
        const csv = [
            headers,
            ...data.map(row => Object.values(row).map(value => value ? `"${value}"` : `""`).join(','))
        ].join('\n');
        return `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
    }


  /*   const exportTable = () => {
        try {
            (dt.current as any).exportCSV();
        } catch (error) {
            // (toast.current as any).show({ severity: 'error', summary: 'Error Message', detail: 'Tablo cant be export' });
        }
    }
 */

    const exportTable = () => {
        try {
            if (rowSelection.length === 0 || rowSelection.length === values.length) {
                const valuesFormatted = formatDateForExport(values)
                const right_now :any = new Date().toISOString().slice(0, 10)
                downloadCSV(valuesFormatted, `${selectedLayer.name}_${right_now}`)
            } else {
                const rowSelectionFormatted = formatDateForExport(rowSelection)
                const right_now :any = new Date().toISOString().slice(0, 10) // SELECTEDONLY
                downloadCSV(rowSelectionFormatted, `${selectedLayer.name}_${right_now}`)
            }
        } catch (error) {
            // (toast.current as any).show({ severity: 'error', summary: 'Error Message', detail: 'Tablo cant be export' });
        }
    }

    const close = () => {
        setTableOpened(false)
        dispatch(setTableButton(false))
        // dispatch(setSelectedLayer({}))
        dispatch(setSelectedLayerGeoserverUrl(''))
        dispatch(setWorkspace(''))
    }

    const dateFilterTemplate = (options: any) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />
    }

    const dateBodyTemplate = (rowData: any, field_name: string) => {
        return formatDate(rowData[field_name]);
    }

    const domainFilterTemplate = (options: any, field: IField) => {
        return <MultiSelect value={options.value}
            options={field.domain?.map(obj => { return { label: obj.desc, value: obj.id } })}
            onChange={(e) => { options.filterCallback(e.value); }} />
    }

    const formatDate = (value: any) => {
        const date = new Date(value)
        return date.toLocaleDateString('tr-TR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }

    const formatDateForExport = (values: any) => {
        const copy = [...values]
        for (let i = 0; i < copy.length; i++) {
            const row = copy[i];
            const entries = Object.entries(row);
            for (const [key, value] of entries) {
                if (key == 'created_date' || key == 'touch_date' || key == 'createdDate' || key == 'touchDate' || key == 'createddate' || key == 'touchdate') {
                    if (value instanceof Date) {
                        const newDate = value.toLocaleDateString('tr-TR', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            timeZoneName: 'short',
                        });
                        copy[i][key] = newDate
                    }
                }
            }
        }
        return copy
    }

    const header = (
        <div className='header-container'>
            <div className='group'>
                <p>{selectedLayer.alias}</p>
                <Button
                    className="table-button p-button-outlined"
                    label={t('TABLE.Go To Selected Data')}
                    onClick={goToSelectedData}
                    disabled={rowSelection ? rowSelection.length === 0 : false}
                />
                <Button
                    className="table-button p-button-outlined p-button-warning"
                    label={t('TABLE.Clear Selection')}
                    onClick={clearSelectedData}
                    disabled={rowSelection ? rowSelection.length === 0 : false}
                />
                <Button
                    className="table-button p-button-outlined p-button-success"
                    label={t('TABLE.Edit')}
                    disabled={rowSelection ? rowSelection.length === 0 : false}
                    onClick={editSelectedData}
                />
                <Button
                    className="table-button p-button-outlined p-button-danger"
                    label={t('TABLE.Delete')}
                    disabled={rowSelection ? rowSelection.length === 0 : false}
                    onClick={() => dispatch(setButtonState(ButtonState.DELETE))}
                />
                <Button
                    className="table-button p-button-outlined p-button-help"
                    label={t('TABLE.Refresh')}
                    onClick={() => getFeatures()}
                />
                <Button
                    className="table-button p-button-outlined p-button-secondary"
                    label={t('TABLE.Clear Filter')}
                    onClick={() => { setFilters3([]); setAllFilters([]); setOtherFilter([]); setCreatedByFilter([]); setFilterClear(true) }}
                />
                <Button
                    className="table-button p-button-outlined"
                    label={t('TABLE.Export CSV')}
                    onClick={exportTable}
                />
            </div>
            <div className='icon'>
                <i className='pi pi-times' onClick={close}></i>
            </div>
        </div>
    );

    const zart = <div>{t('TABLE.Total Record')} : {totalRecords}</div>
    const zart2 = <div>{t('TABLE.Chosen Rows')} : {rowSelection.length}</div>

    return (
        <div className='card' style={{ paddingBottom: displayPrint ? "3%" : "" }} >
            {tableData &&
                <><DataTable
                    style={{ height: '100%' }}
                    ref={dt}
                    value={values}
                    selection={rowSelection}

                    selectionMode={'checkbox'}

                    // onRowClick={(e:any)=>{
                    //     console.log(!rowSelection.find((e:any) => e.id === e.data?.id));

                    //     if(!rowSelection.find((e:any) => e.id === e.data?.id)){
                    //         // setRowSelection([e.data])
                    //         console.log("Hi",e.data?.id);

                    //     }
                    // }}
                    onRowDoubleClick={(e: any) => {
                        setRowSelection([e.data]);
                        setTimeout(() => {
                            dispatch(setGoToSelection(true));
                            setTimeout(() => {
                                setRowSelection([...rowSelection, e.data]);
                            }, 5);
                        }, 100);
                    }}
                    onSelectionChange={(e: any) => {
                        setRowSelection(e.value);
                    }}
                    onPage={onPage}
                    onFilter={onFilter}
                    rows={rows}
                    loading={tableLoading ? true : false}
                    header={header}
                    first={first}
                    filters={filters}
                    onSort={onSort}
                    totalRecords={totalRecords}
                    sortField={sortProperties.sortField}
                    sortOrder={sortProperties.sortOrder}
                    paginatorRight={zart}
                    paginatorLeft={zart2}
                    lazy
                    paginator
                    showGridlines
                    stripedRows
                    resizableColumns
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    className='table-style'
                    columnResizeMode='fit'
                    responsiveLayout="scroll"
                    filterDisplay="menu"
                    emptyMessage='No data found.'
                    paginatorPosition="top"
                    currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
                >
                    {columns}
                </DataTable></>}
        </div >
    );
}
